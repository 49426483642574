import React from 'react'
import { Link } from 'react-router-dom'

function PriceCard({location, price,img,alt, description}) {
  return (
    <div className="card md:w-96 bg-neutral shadow-xl sm:w-72" style={{color:"white"}}>
  <figure className='h-52'><img className='object-cover' src={img} alt={alt}/>
</figure>
  <div className="card-body">
    <div className='flex justify-between pb-4'>
    <h2 className="card-title justify-end">{location}</h2>
    <h2 className="card-title justify-end" style={{color:"#747FFF"}}>{price}</h2>    
    </div>
    <p className='font-thin'>{description}</p>
    <div className="card-actions justify-center pt-4" >
      <Link to="/book" className="btn btn-primary transition duration-150 ease-in-out hover:-translate-y-1 hover:scale-110" style={{color:"white"}}>Book Now</Link>
    </div>
  </div>
</div>
  )
  }
export default PriceCard