import React from 'react'
import PriceCard from './PriceCard'

function PriceCardList() {
  return (
    <div className='container m-10 mx-auto my-10 p-4 sm:p-0 '>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-10 '>
            <div className='flex justify-center '>
                <PriceCard location="Niagara Falls (USA)" price="75$" description="Experience the majestic beauty of Niagara Falls from the American side. Book a convenient and comfortable cab ride from Buffalo Airport directly to this natural wonder." alt="View of Niagara Falls from the American side, showcasing the breathtaking waterfalls with mist rising." img="https://www.traveltomtom.net/images/nieuwe_indeling/artikels/usa/niagara_falls/niagara_falls_usa-5.jpg" />
            </div>
            <div className='flex justify-center sm:px-10'>
                <PriceCard location="Niagara Falls (Canada)" price="85$" description="Discover the stunning vistas of Niagara Falls from the Canadian perspective. Our cab service offers a seamless journey from Buffalo Airport to the heart of Canada's most famous waterfalls." alt="Scenic view of Niagara Falls from the Canadian side, highlighting the expansive waterfalls and lush surroundings." img="https://media.cntraveler.com/photos/5b311699fe04d40b64b22c9e/16:9/w_2560,c_limit/Niagara-Falls_GettyImages-959566100.jpg" />
            </div>
        </div>
    </div>
  )
}

export default PriceCardList