import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useEffect } from 'react';
import Navbar from "./components/layouts/Navbar";
import Hero from "./components/content/Hero";
import ServiceCard from "./components/content/ServiceCard";
import PriceCardList from "./components/content/PriceCardList";
import Car from "./components/content/Car";
import Faqs from "./components/content/Faqs";
import Phone from "./components/content/Phone";
import Form from "./components/content/Form";
import Footer from "./components/layouts/Footer";
import ReviewCard from "./components/content/ReviewCard";
import Contact from "./components/content/Contact";


function App() {

    useEffect(() => {
      document.body.setAttribute('data-theme', 'dark');
    }, []);

    
  

    
  return (
    <Router>
      <div className="min-h-screen" >
      <div  className="flex flex-col  font-poppins ">
        
        <Routes>
          <Route exact path="/" element={
            <>
            <Phone />
            <Navbar/>
            <Hero/>
            <Car/>
            <ReviewCard/>
            <ServiceCard/>
            <PriceCardList/>
            {/* <main>
              Content
            </main> */}
            <Footer/>
            </>
          }>    
          </Route>

          <Route exact path="/faq" element={
            <>
            <Phone />
            <Navbar/>
            <Faqs/>
            </>
          }>    
          </Route>


          <Route exact path="/about" element={
            <>
            <Navbar/>
          
            </>
          }>    
          </Route>

          <Route exact path="/contact" element={
            <>
            <Navbar/>
            <Contact/>
            <Footer/>
            </>
          }>    
          </Route>

          <Route exact path="/book" element={
            <>
            <Phone/>
            <Navbar/>
            <Form/>
            </>
          }>    
          </Route>
        
        </Routes>
        
        
      </div>
      </div>
    </Router>
  );
}

export default App;
