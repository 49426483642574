import React from 'react'
import TextInput from './TextInput'
import { useState, useEffect } from 'react'
import Location from './Location'

function Form() {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [pickup, setPickup] = useState("");
    const [destination, setDestination] = useState("");
    const [pickupDate, setPickupDate] = useState("");
    const [pickupTime, setPickupTime] = useState("");
    const [message, setMessage] = useState("");
    const [useCurrentLocation, setUseCurrentLocation] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null); // State for alert message
    const [isBooked, setIsBooked] = useState(false);
    const [confirmation, setConfirmation] = useState(null)

    const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001' || 'https://eclectic-semifreddo-5d5a35.netlify.app';

    const isEmailValid = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
   

    const handleSubmit = async (e) => {
      e.preventDefault();

      if (!name || !phone || !mail || !pickup || !destination || !pickupDate || !pickupTime) {
        setAlertMessage('All fields except message are required');
        return;
      }

      if (!isEmailValid(mail)) {
        setAlertMessage('Invalid email address');
        return;
      }

      
      const ConfirmationNum = Math.floor(Math.random() * 1000);
      setConfirmation(ConfirmationNum);
      

      console.log(confirmation)
    
      const emailData = {
        ConfirmationNum,
        name,
        phone,
        pickup,
        destination,
        pickupDate,
        pickupTime,
        message,
        mail,
      };
    
      try {
        const response = await fetch(`${BASE_URL}/book`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(emailData),
        });
    
        if (response.ok) {
          console.log('Email sent successfully');
          setIsBooked(true);
          // Handle success, e.g., show a confirmation message to the user
        } else {
          console.error('Error sending email');
          setIsBooked(false);
          // Handle error, e.g., show an error message to the user
        }
      } catch (error) {
        console.error('Error sending email:', error);
        setIsBooked(false);
        // Handle error, e.g., show an error message to the user
      }
    };

    useEffect(() => {
        if (useCurrentLocation) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const geocoder = new window.google.maps.Geocoder();
                    const latLng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    geocoder.geocode({ 'location': latLng }, (results, status) => {
                        if (status === 'OK') {
                            if (results[0]) {
                                setPickup(results[0].formatted_address);
                            }
                        } else {
                            console.log('Geocoder failed due to: ' + status);
                        }
                    });
                }, (error) => {
                    console.error(error);
                });
            } else {
                console.log("Geolocation is not supported by this browser.");
            }
        }

        if(!useCurrentLocation){
            setPickup("")
        }
    }, [useCurrentLocation]);

    useEffect(() => {
        // Load the Google Places Autocomplete
        const loadAutocomplete = () => {
          try {
            const options = { types: ['geocode'] };
            const pickupInput = document.getElementById('pickup');
            const destinationInput = document.getElementById('destination');
            
            let autocompletePickup, autocompleteDestination;
        
            if (pickupInput) {
              autocompletePickup = new window.google.maps.places.Autocomplete(pickupInput, options);
              autocompletePickup.addListener('place_changed', () => {
                const place = autocompletePickup.getPlace();
                setPickup(place.formatted_address);
              });
            }
        
            if (destinationInput) {
              autocompleteDestination = new window.google.maps.places.Autocomplete(destinationInput, options);
              autocompleteDestination.addListener('place_changed', () => {
                const place = autocompleteDestination.getPlace();
                setDestination(place.formatted_address);
              });
            }
          } catch (error) {
            console.error("Google Places API error: ", error);
          }
        };
    
        // Ensure the Google Maps scripts are loaded before initializing Autocomplete
        if (window.google && window.google.maps) {
          loadAutocomplete();
        } else {
          const intervalId = setInterval(() => {
            if (window.google && window.google.maps) {
              clearInterval(intervalId);
              loadAutocomplete();
            }
          }, 1000);
        }
    }, []);
    

    // ... other handler functions ...

    const toggleCurrentLocation = () => {
        setUseCurrentLocation(!useCurrentLocation);
    };

  

      
  return (
    <div className="flex flex-col justify-center py-10 px-4">
      {isBooked? 
      
      <div className='container justify-center md:my-10 md:mx-0 lg:mx-auto md:px-0 px-4 mb-5' style={{color:"white"}}>
        <div class=" justify-center shadow-lg bg-neutral rounded-lg container p-4">
            <div className='flex justify-center p-5'>
                <h1 className='text-3xl md:text-4xl font-bold text-center'>Your cab booking has been successfully confirmed!</h1>
            </div>
            <div className='flex justify-center p-5 text-center'>
                <h1 className='text-3xl md:text-4xl font-bold ' style={{color:"#7CFC00"}}>Confirmation number: {confirmation}</h1>
            </div>
            
            <div className='flex font-thin justify-center p-4  text-center'>
            <p>We value your feedback! Your review helps us improve our service and assists other travelers.</p>
            </div>
            <div className="flex justify-center py-5">
            <a href='https://g.page/r/CWcP9OZ7_adpEBE/review'><button className="btn btn-primary transition duration-150 ease-in-out hover:-translate-y-1 hover:scale-110" style={{color:"white"}}>Write Review</button>    
            </a>
            {/* <a href="https://www.google.com/maps/place/BT+Buffalo+Airport+Taxi/@42.9129992,-78.8555736,17z/data=!4m8!3m7!1s0x2b8d0715f9dfab93:0x69a7fd7be6f40f67!8m2!3d42.9129992!4d-78.8555736!9m1!1b1!16s%2Fg%2F11v4n4550k?hl=en-US&entry=ttu"><button className="btn btn-primary transition duration-150 ease-in-out hover:-translate-y-1 hover:scale-110"  style={{color:"white"}}>Write Review</button>    
            </a> */}
            </div>
            
        </div>
    </div>
      
          : <div className='form-control flex flex-col bg-neutral mx-auto p-8 w-full sm:w-[500px] rounded-xl' style={{color:"white"}}>
            <h1 className='text-3xl text-center sm:text-4xl font-bold my-5'>Book your cab now!</h1>
            <TextInput id="name" type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)}/>
            <TextInput id="phone" type="text" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
            <TextInput id="mail" type="text" placeholder="Email" value={mail} onChange={(e) => setMail(e.target.value)}/>

            <Location checked={useCurrentLocation} onChange={toggleCurrentLocation}/> 
            <TextInput id="pickup" type="text" placeholder="Pickup Address" value={pickup} onChange={(e) => setPickup(e.target.value)}/>
            <TextInput id="destination" type="text" placeholder="Destination Address" value={destination} onChange={(e) => setDestination(e.target.value)}/>
            {/* <TextInput id="date" type="date" placeholder="Destination Address" value={pickupDate} onChange={(e) => setPickupDate(e.target.value)}/>
            <TextInput id="time" type="time" placeholder="Time" value={pickupTime} onChange={(e) => setPickupTime(e.target.value)}/> */}
            <div className="label mt-5">
             <span className="label-text">Date</span>
            </div>
            <input id="date" type="date" placeholder="Pickup Date" value={pickupDate} onChange={(e) => setPickupDate(e.target.value)} className="input input-bordered input-primary w-full sm:w-auto  "></input>

            {/* <TextInput id="date" type="date" placeholder="Pickup Date" className="input input-bordered input-primary w-full sm:w-auto" value={pickupDate} onChange={(e) => setPickupDate(e.target.value)}/> */}
            <div className="label mt-5">
             <span className="label-text">Time</span>
            </div>
            <input id="time" type="time" placeholder="Pickup Time" value={pickupTime} onChange={(e) => setPickupTime(e.target.value)} className="input input-bordered input-primary w-full sm:w-auto  "></input>

            {/* <TextInput id="time" type="time" placeholder="Pickup Time" className="input input-bordered w-full sm:w-auto" value={pickupTime} onChange={(e) => setPickupTime(e.target.value)}/> */}

                
            <textarea className="textarea textarea-primary  mt-5" placeholder="Message" value={message} onChange={(e)=> setMessage(e.target.value)}></textarea>
            {/* Add other fields */}
            {alertMessage && (
            <div className="bg-red-500 text-white p-3 mt-4 rounded">
              {alertMessage}
            </div>
             )}
            <div className="flex justify-center mt-5"> {/* Container for centering the button */}
                <button type="submit" onClick={handleSubmit} className="btn w-1/2 btn-primary text-lg transition duration-150 ease-in-out hover:-translate-y-1 hover:scale-105" style={{color:"white"}}>Submit</button>
            </div>        
          </div>}
        
    </div>
  )
}

export default Form