import React from 'react'

function Location({checked, onChange}) {
  return (
    <div className="form-control mt-5">
  <label className="label cursor-pointer">
    <span className="label-text">Use Curent Location</span> 
    <input type="checkbox" checked={checked} onChange={onChange} className="checkbox checkbox-primary" />
  </label>
</div>
  )
}

export default Location