import React from 'react';

function ServiceCard() {
  return (
    <div className='container md:my-10 md:mx-0 lg:mx-auto md:px-0 px-4 font-poppins'>
      <div className="shadow-lg bg-neutral rounded-lg container p-4" style={{ color: "white" }}>
        <div className='flex justify-center'>
          <h1 className='text-4xl font-bold my-5'>Our Services</h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Column 1 */}
          <div className=' font-thin px-4 sm:px-8 pt-5 md:py-5'>
            <h2 className="font-thin text-2xl mb-4">Airport Transfers</h2>
            <ul className='font-thin'>
              <li>• Stress-free airport transfers between Buffalo Airport and Niagara Falls.</li>
              <li>• Both U.S. and Canadian sides of Niagara Falls.</li>
            </ul>

            <h2 className=" text-2xl mt-4 mb-2">Affordable Rates</h2>
            <ul className='font-thin'>
              <li>• Enjoy competitive and budget-friendly pricing for your ride.</li>
              <li>• Transparent pricing with no hidden fees.</li>
            </ul>
          </div>

          {/* Column 2 */}
          <div className=' font-thin px-4 sm:px-8 pb-5 md:py-5'>
            <h2 className=" text-2xl mb-2">Experienced Drivers</h2>
            <ul className='font-thin'>
              <li>• Our skilled drivers ensure your safety and comfort during your journey.</li>
              <li>• Knowledgeable about the local area and tourist attractions.</li>
            </ul>

            <h2 className=" text-2xl mt-4 mb-2">Flexible Booking</h2>
            <ul className='font-thin'>
              <li>• Book in advance or call for immediate service.</li>
              <li>• We are here to meet your transportation needs 24/7.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
