import React from 'react'
import {FaPhone} from 'react-icons/fa'

function Phone() {
  return (
    <div className="sm:hidden animate-bounce fixed right-8 bottom-8 z-50 transform -translate-y-1/2">
      <a  className="link " href="tel:+17169516256" >
        <FaPhone className="rotate-90 text-4xl" style={{color:"7CFC00"}}/>
      </a> 
    </div>
  )
}

export default Phone