import React from 'react'
import {FaYelp, FaGoogle} from 'react-icons/fa'


function Footer() {
  return (
    <>
    <footer className="footer p-10 bg-base-200 text-base-content">
  <nav>
    <header className="footer-title">PHONE</header>
    <a  className="link link-hover" href="tel:+17165414864" >
        +1 (716) 951-6256
      </a> 
    
  </nav> 
  <nav>
    <header className="footer-title">MAIL</header> 
    <a className="link link-hover" href = "mailto: btbuffallotaxi@gmail.com">btbuffallotaxi@gmail.com</a>

  </nav> 
  <nav>
    <header className="footer-title">WRITE US A REVIEW</header> 
    <a href="https://g.page/r/CWcP9OZ7_adpEBE/review" className="btn btn-sm rounded-btn btn-primary ml-2"  style={{color:"white"}} target="_blank" rel="noopener noreferrer">
      Write Review
    </a>
  </nav>
</footer> 
<footer className="footer px-10 py-4 border-t bg-base-200 text-base-content border-base-300">
  <aside className="items-center grid-flow-col">
  <img src='/images/Logo.png' alt='Icon' className="inline pr-2 pb-1" style={{width:"40px", height:"35px"}}/>

    <p>BT Buffalo Airport Taxi <br/>Providing reliable service in Buffalo</p>
  </aside> 
  <nav className="md:place-self-center md:justify-self-end">
    <div className="grid grid-flow-col gap-4">
    <a href='https://www.yelp.com/biz/bt-buffalo-airport-taxi-buffalo'> <FaYelp className=" text-4xl pb-[5px] bg-base-200" /></a>
    <a href='https://www.google.com/maps/place/BT+Buffalo+Airport+Taxi/@42.9129992,-78.8581485,17z/data=!4m8!3m7!1s0x2b8d0715f9dfab93:0x69a7fd7be6f40f67!8m2!3d42.9129992!4d-78.8555736!9m1!1b1!16s%2Fg%2F11v4n4550k?entry=ttu'>    <FaGoogle className=" text-4xl pb-[5px] bg-base-200" />
</a>


      
    </div>
  </nav>
</footer>
</>
  )
}

export default Footer