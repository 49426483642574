import React from 'react'

function ReviewCard() {
  return (
    <div className='container justify-center md:my-10 md:mx-0 lg:mx-auto md:px-0 px-4 mb-5' style={{color:"white"}}>
        <div class=" justify-center shadow-lg bg-neutral rounded-lg container p-4">
            <div className='flex justify-center p-5'>
                <h1 className='text-4xl font-bold '>Your Feedback</h1>
            </div>
            
            <div className='flex font-thin justify-center px-4 '>
            <p>We value your feedback! Your review helps us improve our service and assists other travelers.</p>
            </div>
            <div className="flex justify-center py-5">
            {/* <a href='https://www.google.com/search?hl=en-US&gl=us&q=BT+Buffalo+Airport+Taxi,+65+Verplanck+St,+Buffalo,+NY+14208&ludocid=7613332403690671975&lsig=AB86z5UBqGGjsu2jMAZH2tcj1T_b#lrd=0x2b8d0715f9dfab93:0x69a7fd7be6f40f67,3'><button className="btn btn-primary transition duration-150 ease-in-out hover:-translate-y-1 hover:scale-110" style={{color:"white"}}>Write Review</button>     */}
{/* </a> */}
     <a href='https://g.page/r/CWcP9OZ7_adpEBE/review'><button className="btn btn-primary transition duration-150 ease-in-out hover:-translate-y-1 hover:scale-110" style={{color:"white"}}>Write Review</button>    
    </a>
            {/* <a href="https://www.google.com/maps/place/BT+Buffalo+Airport+Taxi/@42.9129992,-78.8555736,17z/data=!4m8!3m7!1s0x2b8d0715f9dfab93:0x69a7fd7be6f40f67!8m2!3d42.9129992!4d-78.8555736!9m1!1b1!16s%2Fg%2F11v4n4550k?hl=en-US&entry=ttu"><button className="btn btn-primary transition duration-150 ease-in-out hover:-translate-y-1 hover:scale-110"  style={{color:"white"}}>Write Review</button>    
            </a> */}
            </div>
            
        </div>
    </div>
  )
}

export default ReviewCard