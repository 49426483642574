import React from 'react'

function Car() {
  return (
    <div className='container mx-auto md:mt-10 p-10 justify-middle'>
        <figure className=' justify-center'>
            <img src="/images/rav4.png" alt="Buffalo Airport Taxi"/>
        </figure>
    </div>
  )
}

export default Car