import React from 'react';
import { Link } from 'react-router-dom';

function Hero() {
  return (
    

<div className="hero h-[410px] md:h-96 bg-base-200 bg-cover bg-center" style={{backgroundImage: 'url(https://www.niagarafallstourism.com/site/assets/files/14135/bryan-goff-511613-unsplash.jpg)'}}>
<div className="hero-overlay bg-opacity-60"></div>
      <div className="hero-content text-neutral-content text-center">
        <div  style={{color:"white"}}>
          <h1 className="mb-5 text-5xl font-bold ">Welcome to BT Buffalo Airport Taxi Service!</h1>
          <ul className="mb-5">
            <li>
            Offering Reliable and Affordable Airport Transportation to niagara falls NY, niagara falls Canadian side, Toronto and to buffalo airport from anywhere.
            </li>
            <li>
            Convenient and cost-effective transportation solutions for travelers.
            </li>
          </ul>
          
          <Link to="/book" className="btn btn-primary transition duration-150 ease-in-out hover:-translate-y-1 hover:scale-110 " style={{color:"white"}}>Book Now!</Link>
        </div>
      </div>
</div>
  );
}

export default Hero;
