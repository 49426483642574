import React from 'react'

function Faqs() {
  return (
    <div className='flex flex-col h-screen justify-center p-10 pb-40' style={{color:"white"}}>
        <div className="collapse collapse-arrow bg-base-200 bg-neutral my-2 ">
            <input type="radio" name="my-accordion-2" /> 
            <div className="collapse-title text-xl font-medium bg-neutral">
            <h1>What is the distance from Buffalo Airport to Niagara Falls?</h1>
            </div>
            <div className="collapse-content bg-neutral"> 
              <p>The distance varies depending on your destination within Niagara Falls, but it's approximately 20-30 miles.</p>
            </div>
        </div>
        <div className="collapse collapse-arrow bg-base-200 my-2 ">
            <input type="radio" name="my-accordion-2" /> 
            <div className="collapse-title text-xl font-medium bg-neutral ">
            <h1>How much does a ride cost from Buffalo Airport to Niagara Falls?</h1>
            </div>
            <div className="collapse-content bg-neutral"> 
             <p>Our rates are competitive and affordable. Pricing depends on your specific destination.</p>
            </div>
        </div>
        <div className="collapse collapse-arrow bg-base-200 my-2">
            <input type="radio" name="my-accordion-2" /> 
            <div className="collapse-title text-xl font-medium bg-neutral">
            <h1>Do you provide transportation to both the U.S. and Canadian sides of Niagara Falls?</h1>
            </div>
            <div className="collapse-content bg-neutral"> 
            <p>Yes, we offer transportation to both sides, making it easy for you to explore all that Niagara Falls has to offer.</p>
            </div>
        </div>
        <div className="collapse collapse-arrow bg-base-200 my-2">
            <input type="radio" name="my-accordion-2" /> 
            <div className="collapse-title text-xl font-medium bg-neutral">
            <h1>Can I book a ride in advance?</h1>
            </div>
            <div className="collapse-content bg-neutral"> 
            <p>Absolutely! You can book online or give us a call to make a reservation.</p>
            </div>
        </div>
    </div>
  )
}

export default Faqs