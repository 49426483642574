import React from 'react'

function Contact() {
  return (
    <div className='container py-10 md:my-10 md:mx-0 lg:mx-auto md:px-0 px-4 font-poppins'>
      <div className="shadow-lg bg-neutral rounded-lg container py-4 sm:p-4" style={{ color: "white" }}>
        <div className='flex justify-center'>
          <h1 className='text-4xl font-bold my-5'>Contact Us</h1>
        </div>

        <div className='text-lg sm:text-2xl font-thin px-4 sm:px-10 flex flex-row'>
            <h1 className=' px-5 sm:px-10'>Tel:</h1>
            <a  className="link " href="tel:+17165414864" >
            +1 (716) 951-6256
             </a> 
        </div>

        <div className='text-lg sm:text-2xl font-thin px-4 sm:px-10 py-5 flex flex-row'>
            <h1 className='px-5 sm:px-10'>Email:</h1>
            <a className="link" href = "mailto: btbuffallotaxi@gmail.com">btbuffallotaxi@gmail.com</a>
        </div>

        <div className='text-lg sm:text-2xl font-thin px-4 sm:px-10 flex flex-row'>
            <h1 className='px-5 sm:px-10 pb-5'>Address:</h1>
            <a className="link" href = "https://www.google.com/maps/place/BT+Buffalo+Airport+Taxi/@42.9129992,-78.8555736,15z/data=!4m2!3m1!1s0x0:0x69a7fd7be6f40f67?sa=X&ved=2ahUKEwjflLfYx-KCAxX6GFkFHa8oACUQ_BJ6BAhXEAA">65 Verplanck St, Buffalo, NY 14208</a>
        </div>

      </div>

      <div className='my-6 rounded-lg'>
          <iframe 
            className=' h-96 sm:h-[500px] rounded-lg'
            title="BT Buffalo Airport Taxi Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2921.660014127396!2d-78.85776238454175!3d42.91300217915571!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d312b7e0a576c1%3A0x69a7fd7be6f40f67!2s65%20Verplanck%20St%2C%20Buffalo%2C%20NY%2014208%2C%20USA!5e0!3m2!1sen!2s!4v1650443412387!5m2!1sen!2s"
            width="100%"
            // height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
    </div>
  )
}

export default Contact