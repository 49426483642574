import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; // Ensure react-icons is installed

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => setIsOpen(false);


  return (
    <nav className='navbar shadow-lg bg-neutral text-neutral-content' style={{ zIndex: 1000, position: 'relative' }}>
        <div className='container mx-auto flex justify-between items-center'>
            {/* Logo and Title */}
            <div className='flex items-center'>
                <img src='/images/Logo.png' alt='Icon' className="inline pr-2" style={{width:"40px", height:"35px"}}/>
                <Link to='/' className='text-lg font-bold align-middle'>
                    BT Buffalo
                </Link>
            </div>

            {/* Menu Toggle Icon */}
            <div className='md:hidden z-200 pr-5' onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? <FaTimes className="text-2xl" /> : <FaBars className="text-2xl " />}
            </div>

            {/* Links */}
            <div className={`absolute inset-x-0 top-0 p-2 transition transform origin-top-right md:relative md:inset-0 md:p-0 ${isOpen ? 'block' : 'hidden'} md:block`}>
                <div className='rounded-lg shadow-md bg-neutral p-2 md:bg-transparent md:p-0 md:shadow-none'>
                    <div className='flex flex-col md:flex-row justify-end md:items-center'>
                        <Link to="/faq" className='btn  btn-ghost btn-sm rounded-btn mt-2 md:mt-0' onClick={closeMenu}>FAQs</Link>
                        {/* <Link to="/about" className='btn btn-ghost btn-sm rounded-btn mt-2 md:mt-0' onClick={closeMenu}>About</Link> */}
                        <Link to="/contact" className='btn btn-ghost btn-sm rounded-btn mt-2 md:mt-0' onClick={closeMenu}>Contact Us</Link>
                        <a href="https://g.page/r/CWcP9OZ7_adpEBE/review" onClick={closeMenu} className="btn btn-sm rounded-btn btn-primary ml-2 mt-2 md:mt-0" target="_blank" rel="noopener noreferrer" style={{color:"white"}}>
                            Write Review
                        </a>
                        <button onClick={closeMenu} className="btn btn-ghost btn-sm rounded-btn mt-2 md:mt-0 sm:hidden">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </nav>
  );
}

export default Navbar;


